.skills {
    margin: 0 auto;
    margin-top: 20px;
    max-width: 800px;
    padding: 20px;
    background-color: var(--color-secondary-background);
    color: var(--color-secondary-text);
    border-radius: 5px;
    box-shadow: 0 0 10px var(--color-shadow);
    text-align: center;
}

.skills h1 {
    font-size: 2em;
    margin-bottom: 20px;
}

.skills-list {
    list-style-type: none;
    padding: 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.skill-item {
    background-color: var(--color-primary);
    color: var(--color-primary-text);
    padding: 10px 15px;
    margin: 10px;
    border-radius: 5px;
    font-size: 1.1em;
    transition: transform 0.2s;
}

.skill-item:hover {
    transform: scale(1.05);
}