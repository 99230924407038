/* Load root default styles */
:root {
    --color-primary-background: #fff;
    --color-primary-text: #333;
    --color-secondary-background: #dbdbdb;
    --color-secondary-text: #3f3f3f;
    --color-link: #96c7fc;
    --color-link-hover: #33aeff;
    --color-link-active: #0077ff;
    --color-link-background: #f8f9fa;
    --color-shadow: #0c0c0c
}

* {
    transition: color 0.5s, background-color 0.5s;
}