.about {
    margin: 0 auto;
    margin-top: 20px;
    max-width: 800px;
    padding: 20px;
    background-color: var(--color-secondary-background);
    color: var(--color-secondary-text);
    border-radius: 5px;
    box-shadow: 0 0 10px var(--color-shadow);
}

.about h1 {
    font-size: calc(7px + 2vmin);
    margin-bottom: 20px;
}

.about h2 {
    font-size: calc(4px + 2vmin);
    margin-top: 30px;
    margin-bottom: 10px;
}

.about h3 {
    font-size: calc(2px + 2vmin);
    margin-bottom: 5px;
    font-weight: normal;
}

.about p {
    margin-bottom: 15px;
}

.job,
.about-item {
    margin-bottom: 30px;
}

.job-details,
.about-details {
    margin-left: 20px;
}

.job-details p,
.about-details p {
    margin-bottom: 10px;
}

/* 760px width */
@media (max-width: 760px) {
    .about h3 {
        font-size: calc(5px + 2vmin);
    }
}