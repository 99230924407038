.App {
  text-align: center;
  display: flex;
  text-align: center;
  flex-direction: column;
  min-height: 80vh;
  padding: 3em;
}

.App-logo {
  height: 5vmin;
  pointer-events: none;
}

.App-header {
  background-color: var(--color-primary-background);
  min-height: 10vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(2px + 2vmin);
  color: var(--color-primary-text);
}

@media (max-width: 600px) {
  .App-header {
    font-size: calc(5px + 2vmin);
  }
}