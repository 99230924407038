.hamburger {
    display: none;
    pointer-events: none;
}

.nav-links {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 5vh;
}

.App-nav a {
    color: var(--color-secondary-text);
    margin: 0 10px;
    text-decoration: none;
    background-color: var(--color-secondary-background);
    padding: 5px 10px;
    border-radius: 10px;
}

.App-nav a:hover,
.App-nav a.active {
    background-color: var(--color-link-background);
    box-shadow: 0 0 9px 0 var(--color-shadow);
    color: var(--color-link-hover);
}

.App-nav a.active {
    color: var(--color-link-active);
}

@media (max-width: 600px) {
    .hamburger {
        display: block;
        font-size: 24px;
        background: none;
        border: none;
        cursor: pointer;
        color: var(--color-secondary-text);
        position: absolute;
        right: 20px;
        top: 15px;
        pointer-events: auto;
    }

    .nav-links {
        left: -150%;
        transition: left 0.3s ease-in-out;
        position: fixed;
        top: 50px;
        width: 100%;
        background-color: var(--color-secondary-background);
        padding: 10px 0;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    }

    .nav-links.open {
        left: 0;
    }

    .App-nav {
        flex-direction: column;
        align-items: center;
    }

    .App-nav a {
        margin: 10px 10px;
        width: 100%;
        text-align: center;
    }

    .nav-links.open .App-nav {
        display: flex;
        flex-direction: row;
        align-items: center;
    }
}