.contact {
    max-width: 1200px;
    margin: 0 auto;
}

.contact .contact-info {
    display: flex;
    margin: 0 auto;
    padding: 0 20px;
    align-items: center;
    gap: 20px;
}

.contact .contact-info img {
    width: 250px;
    height: 250px;
    border-radius: 50%;
}

.image-placeholder * {
    width: 250px;
    height: 250px;
    border-radius: 50%;
    background-color: #ccc;
}

@media screen and (max-width: 790px) {
    .contact .contact-info {
        flex-direction: column;
    }

    .contact .contact-info img {
        margin: 0 0 20px 0;
    }
}